import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InputSelectOption } from '@unique/component-library';

export interface TranslationState {
  translateToLanguage: InputSelectOption;
  textToTranslate: string;
  isTranslating: boolean;
}

const initialState: TranslationState = {
  translateToLanguage: {
    label: 'English',
    value: 'English',
  },
  textToTranslate: '',
  isTranslating: false,
};

export const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    updateTranslateToLanguage: (state, action: PayloadAction<InputSelectOption>) => {
      state.translateToLanguage = action.payload;
    },
    setTextToTranslate: (state, action: PayloadAction<string>) => {
      state.textToTranslate = action.payload;
    },
    setIsTranslating: (state, action: PayloadAction<boolean>) => {
      state.isTranslating = action.payload;
    },
  },
});

export const { updateTranslateToLanguage, setTextToTranslate, setIsTranslating } =
  translationSlice.actions;

export default translationSlice.reducer;
