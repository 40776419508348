'use client';

import { FC, ReactNode, createContext } from 'react';
import { MAX_FILES, MAX_FILE_SIZE } from '@unique/shared-library';

type ConfigurationContextProps = {
  zitadelIssuer: string;
  zitadelClientId: string;
  selfUrl: string;
  chatUrl: string;
  knowledgeUploadUrl: string;
  adminUrl: string;
  chatBackendUrl: string;
  themeBackendUrl: string;
  ingestionBackendUrl: string;
  scopeManagementBackendUrl: string;
  staticBackgroundColor: string;
  staticThemeColors: string;
  staticTabName: string;
  orgId: string;
  version: string;
  sentryDsn: string;
  basePath: string;
  flags: Record<string, string>;
  maxFiles: number;
  maxFileSize: number;
};

export const ConfigurationContext = createContext<ConfigurationContextProps>({
  zitadelIssuer: null,
  zitadelClientId: null,
  selfUrl: null,
  chatUrl: null,
  knowledgeUploadUrl: null,
  adminUrl: null,
  chatBackendUrl: null,
  themeBackendUrl: null,
  ingestionBackendUrl: null,
  scopeManagementBackendUrl: null,
  staticBackgroundColor: null,
  staticThemeColors: null,
  staticTabName: null,
  orgId: null,
  version: null,
  sentryDsn: null,
  basePath: null,
  flags: {},
  maxFiles: MAX_FILES,
  maxFileSize: MAX_FILE_SIZE,
});

type ConfigurationProviderProps = {
  children: ReactNode;
  config: ConfigurationContextProps;
};

export const ConfigurationProvider: FC<ConfigurationProviderProps> = ({ children, config }) => {
  return (
    <ConfigurationContext.Provider value={config}>
      {children}
    </ConfigurationContext.Provider>
  );
};
