import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface PreferencesState {
  lastSpaceIdVisited: string | null;
}

const initialState: PreferencesState = {
  lastSpaceIdVisited: null,
};

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setLastSpaceIdVisited: (state, action: PayloadAction<string>) => {
      state.lastSpaceIdVisited = action.payload;
    },
  },
});

export const { setLastSpaceIdVisited } =
preferencesSlice.actions;

export default preferencesSlice.reducer;
