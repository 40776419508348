import { logger } from '@unique/next-commons/logger';
import { chatSlice } from '../chatSlice';

import { getSdk } from '@/@generated/graphql';
import { GraphQLClient } from 'graphql-request';

const log = logger.child({
  package: 'chat',
  namespace: 'redux:actions:stopStream',
});

export const stopStream = (chatId: string, messageId: string, client: GraphQLClient) => {
  return async (dispatch) => {
    const { removeStream } = chatSlice.actions;
    try {
      log.info(`dispatch stopStream: ${chatId}`);
      const sdkScope = getSdk(client);
      await sdkScope.MessageStopStreaming({
        chatId,
        messageId,
      });

      return dispatch(
        removeStream({
          chatId,
          messageId,
        }),
      );
    } catch (error) {
      log.error(error.response.errors[0].message);
      return Promise.reject(error.response.errors[0]);
    }
  };
};
